import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Success = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate('/profile');
    }, 3000);
  }, []);

  return (
    <div className='container'>
      <h1>Payment Success</h1>
      <p>Thank you for your payment.</p>
    </div>
  );
};

export default Success;
