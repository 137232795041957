import axios from '../../utils/axios';

export const getTransactionThunk = async (transactionId, thunkApi) => {
  try {
    const res = await axios.get(`/transaction/${transactionId}`);
    return res.data;
  } catch (err) {
    return thunkApi.rejectWithValue(err.response.data);
  }
};

export const getTransactionsThunk = async (_, thunkApi) => {
  try {
    const { page, limit } = thunkApi.getState().transaction.transactions;
    const { start_dt, end_dt } = thunkApi.getState().filter;
    const res = await axios.get('/transaction', {
      params: { page, limit, start_dt, end_dt },
    });
    return res.data;
  } catch (err) {
    return thunkApi.rejectWithValue(err.response.data);
  }
};

const parse = (transaction) => {
  var clone = Object.assign({}, transaction);
  clone.entries = []; // entry is immutable, must rebuild
  for (var i in transaction.entries) {
    const entry = transaction.entries[i];
    var amount = entry.amount;
    clone.entries.push({ ...entry, amount });
  }
  return clone;
};

export const createTransactionThunk = async (_, thunkApi) => {
  try {
    const { transaction } = thunkApi.getState().transaction;
    const clone = parse(transaction);
    const res = await axios.post(`/transaction`, clone);
    return res.data;
  } catch (err) {
    return thunkApi.rejectWithValue(err.response.data);
  }
};

export const updateTransactionThunk = async (_, thunkApi) => {
  try {
    const { transaction } = thunkApi.getState().transaction;
    const clone = parse(transaction);
    const res = await axios.put(`/transaction/${clone.id}`, clone);
    return res.data;
  } catch (err) {
    return thunkApi.rejectWithValue(err.response.data);
  }
};

export const deleteTransactionThunk = async (transactionId, thunkApi) => {
  try {
    const res = await axios.delete(`/transaction/${transactionId}`);
    return res.data;
  } catch (err) {
    return thunkApi.rejectWithValue(err.response.data);
  }
};
