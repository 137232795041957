import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signinUser } from '../../features/user/userSlice';
import { useDispatch } from 'react-redux';
import GoogleButton from 'react-google-button';
import { FullButton } from '../../components';

const Signin = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const handleSignin = async ({ event, email, password }) => {
    event.preventDefault();
    dispatch(signinUser({ email, password })).then((res) => {
      if (res.meta.requestStatus === 'fulfilled') {
        navigate('/home', { replace: true });
      }
    });
  };

  return (
    <form className='page-100'>
      <div className='container d-grid gap-2'>
        <h3>Sign In</h3>
        <div className='form-group'>
          <label>Email Address</label>
          <input
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className='form-control'
          />
        </div>
        <div className='form-group'>
          <label>Password</label>
          <input
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            type='password'
            className='form-control'
          />
        </div>
        <FullButton
          text='Sign In'
          color='primary'
          onClick={(event) => handleSignin({ event, email, password })}
        />

        {/* <FullButton
          text='Demo Account'
          color='secondary'
          onClick={(event) => {
            handleSignin({
              event,
              email: 'test@centaurwealth.io',
              password: '1234',
            });
          }}
        /> */}

        <GoogleButton
          className='w-100'
          type='light'
          onClick={() =>
            (window.location.href = process.env.REACT_APP_GOOGLE_SIGNIN_URL)
          }
        />
      </div>
    </form>
  );
};

export default Signin;
