import React, { useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  Transactions,
  PageButtonContianer,
  DateFilter,
  FullButton,
} from '../../components';
import { format } from '../../utils';
import { useSelector, useDispatch } from 'react-redux';
import { getAccount, getAccounts } from '../../features/account/accountSlice';
import {
  addEntry,
  resetTransaction,
  setPage,
  setLimit,
} from '../../features/transaction/transactionSlice';

const AccountHistory = () => {
  const dispatch = useDispatch();
  const { accountId } = useParams();
  const { account } = useSelector((store) => store.account);
  const { start_dt, end_dt } = useSelector((store) => store.filter);
  const { transactions, transaction } = useSelector(
    (store) => store.transaction
  );

  useEffect(() => {
    dispatch(getAccounts());
  }, [transaction]);

  useEffect(() => {
    dispatch(getAccount(accountId));
  }, [start_dt, end_dt, transactions.page]);

  const setLimitAndFetchPage = (limit) => {
    dispatch(setLimit({ limit }));
    dispatch(setPage({ page: 1 }));
    dispatch(getAccount(accountId));
  };

  const addEntryWithCurrentAccount = () => {
    dispatch(resetTransaction());
    const entry = {
      amount: '0',
      type: 'debit',
      accountId,
      accountType: account.type,
    };
    dispatch(addEntry({ entry }));
  };

  return (
    <div className='container d-grid gap-2'>
      <div className='row'>
        <h4>{account.name}</h4>
        <b>Balance: {format(account.balance)}</b>
      </div>
      <DateFilter />
      <Transactions
        totalPage={transactions.totalPage}
        page={transactions.page}
        setPage={setPage}
        limit={transactions.limit}
        setLimit={setLimit}
      />
      <PageButtonContianer
        totalPages={transactions.totalPages}
        page={transactions.page}
        setPage={setPage}
        limit={transactions.limit}
        setLimit={setLimitAndFetchPage}
      />
      <Link to='/transaction/create'>
        <FullButton
          text='New Transaction'
          color='primary'
          onClick={addEntryWithCurrentAccount}
        />
      </Link>
      <Link to={`/account/detail/${account.id}`}>
        <FullButton text='Edit Account' color='primary' />
      </Link>
    </div>
  );
};

export default AccountHistory;
