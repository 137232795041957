import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@mui/material';
import { format } from '../../utils';
const {
  getPaymentCheckout,
  getPaymentPortal,
} = require('../../features/payment/paymentSlice');
const { FullButton } = require('../../components');

export const Profile = () => {
  const dispatch = useDispatch();
  const { user, beta, subscription } = useSelector((store) => store.user);
  const [open, setOpen] = useState(false);
  const { num_accounts, num_transactions, num_dollars } = user.profile;

  return (
    <div className='container d-grid gap-2'>
      <h4>Profile</h4>

      <p>
        <b>Email: </b>
        {user.profile.email}
      </p>
      <p>
        <b>Date Joined: </b>
        {user.profile.joined}
      </p>
      <p>
        <b>Subscription: </b>
        {subscription}
      </p>
      <p>
        {num_accounts} accounts, {num_transactions} transactions,{' '}
        {format(num_dollars)} accounted{' '}
      </p>

      {!user.profile.subscribed && (
        <FullButton
          disabled={beta}
          text='Subscribe Now'
          color='primary'
          onClick={() => dispatch(getPaymentCheckout())}
        />
      )}

      {user.profile.subscribed && (
        <FullButton
          disabled={beta}
          text='Manage Billing'
          color='primary'
          onClick={() => dispatch(getPaymentPortal())}
        />
      )}

      <Link to={'/auth/signout'}>
        <FullButton text='Signout Account' color='secondary' />
      </Link>

      <FullButton
        text='Delete Account'
        color='error'
        onClick={() => setOpen(true)}
      />

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          Proceed with account deletion?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            This action is irreversible. All data will be deleted.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)}>Cancel</Button>
          <Link to={'/auth/dropuser'}>
            <Button color='error'>Delete Account</Button>
          </Link>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default Profile;
