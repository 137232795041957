import { configureStore } from '@reduxjs/toolkit';
import userSlice from './features/user/userSlice';
import accountSlice from './features/account/accountSlice';
import transactionSlice from './features/transaction/transactionSlice';
import chartSlice from './features/chart/chartSlice';
import filterSlice from './features/filter/filterSlice';
import incomeSlice from './features/income/incomeSlice';
import cashflowSlice from './features/cashflow/cashflowSlice';
import paymentSlice from './features/payment/paymentSlice';

export const store = configureStore({
  reducer: {
    user: userSlice,
    account: accountSlice,
    transaction: transactionSlice,
    chart: chartSlice,
    filter: filterSlice,
    income: incomeSlice,
    cashflow: cashflowSlice,
    payment: paymentSlice,
  },
});
