import React, { useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Transaction, FullButton } from '../../components';
import { useDispatch } from 'react-redux';
import {
  setIsNew,
  getTransaction,
  deleteTransaction,
  updateTransaction,
  resetTransaction,
} from '../../features/transaction/transactionSlice';

const TransactionDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { transactionId } = useParams();

  useEffect(() => {
    dispatch(setIsNew({ isNew: false }));
    dispatch(getTransaction(transactionId));
  }, [transactionId]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(updateTransaction()).then((res) => {
      if (res.meta.requestStatus === 'fulfilled') {
        dispatch(resetTransaction());
        setTimeout(() => navigate(-1), 1000);
      }
    });
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    dispatch(deleteTransaction(transactionId)).then((res) => {
      if (res.meta.requestStatus === 'fulfilled') {
        dispatch(resetTransaction());
        setTimeout(() => navigate(-1), 1000);
      }
    });
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className='container d-grid gap-2'>
          <h4>Update Transaction</h4>
          <Transaction />
          <FullButton text='Update' color='primary' onClick={handleSubmit} />
          <FullButton text='Delete' color='error' onClick={handleDelete} />
          <FullButton
            text='Cancel'
            color='secondary'
            onClick={() => {
              dispatch(resetTransaction());
              navigate(-1);
            }}
          />
        </div>
      </form>
    </div>
  );
};

export default TransactionDetail;
