import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { displayErrors } from '../../utils/toast';
import { getPaymentCheckoutThunk, getPaymentPortalThunk } from './paymentThunk';

const defaultPlan = 'monthly';

const initialState = {
  plan: defaultPlan,
};

export const getPaymentCheckout = createAsyncThunk(
  'payment/checkout',
  getPaymentCheckoutThunk
);

export const getPaymentPortal = createAsyncThunk(
  'payment/portal',
  getPaymentPortalThunk
);

const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    setPlan: (state, { payload }) => {
      state.plan = payload;
    },
  },
  extraReducers: {
    [getPaymentCheckout.fulfilled]: (state, { payload }) => {
      window.location.href = payload.url;
    },
    [getPaymentCheckout.rejected]: (state, { payload }) => {
      displayErrors(payload.errors);
    },
    [getPaymentPortal.fulfilled]: (state, { payload }) => {
      window.location.href = payload.url;
    },
    [getPaymentPortal.rejected]: (state, { payload }) => {
      displayErrors(payload.errors);
    },
  },
});

export const { setPlan } = paymentSlice.actions;
export default paymentSlice.reducer;
