import axios from '../../utils/axios';

export const getCashflowThunk = async (user, thunkApi) => {
  try {
    const { start_dt, end_dt } = thunkApi.getState().filter;
    const res = await axios.get('/statement/cashflow', {
      params: { start_dt, end_dt },
    });
    return res.data;
  } catch (err) {
    return thunkApi.rejectWithValue(err.response.data);
  }
};
