import { Button } from '@mui/material';

const FullButton = ({ text, color, onClick, disabled = false }) => {
  return (
    <div className='row'>
      <div className='col-sm-12'>
        <Button
          variant='outlined'
          color={color}
          disabled={disabled}
          fullWidth
          onClick={onClick}
        >
          {text}
        </Button>
      </div>
    </div>
  );
};

export default FullButton;
