import { createSlice } from '@reduxjs/toolkit';
import { getMonthStart, getMonthEnd } from '../../utils/time';

const initialState = {
  start_dt: getMonthStart(),
  end_dt: getMonthEnd(),
  showAll: false,
};

const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    setStartDt: (state, { payload }) => {
      const { start_dt } = payload;
      state.start_dt = start_dt;
    },
    setEndDt: (state, { payload }) => {
      const { end_dt } = payload;
      state.end_dt = end_dt;
    },
    toggleShowAll: (state) => {
      state.showAll = !state.showAll;
    },
  },
});

export const { setStartDt, setEndDt, toggleShowAll } = filterSlice.actions;
export default filterSlice.reducer;
