import { useSelector, useDispatch } from 'react-redux';
import { setStartDt, setEndDt } from '../features/filter/filterSlice';

const DateFilter = () => {
  const dispatch = useDispatch();
  const { start_dt, end_dt } = useSelector((store) => store.filter);

  return (
    <>
      <div className='row'>
        <div className='col-6'>
          <label htmlFor='start_dt' className='form-label'>
            Start Date
          </label>
          <input
            type='date'
            className='form-control'
            id='start_dt'
            value={start_dt}
            onChange={(e) => dispatch(setStartDt({ start_dt: e.target.value }))}
          />
        </div>
        <div className='col-6'>
          <label htmlFor='end_dt' className='form-label'>
            End Date
          </label>
          <input
            type='date'
            className='form-control'
            id='end_dt'
            value={end_dt}
            onChange={(e) => dispatch(setEndDt({ end_dt: e.target.value }))}
          />
        </div>
      </div>
    </>
  );
};

export default DateFilter;
