import React from 'react';
import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Header, Footer } from './components';
import Home, { Landing, Error, ProtectedRoute, SharedLayout } from './pages';
import { Signin, Signup, Signout, Delete } from './pages/auth';
import Account, {
  AccountCreate,
  AccountDetail,
  AccountHistory,
} from './pages/account';
import Income from './pages/income';
import Cashflow from './pages/cashflow';
import Profile from './pages/profile';
import { Success, Failure } from './pages/payment';
import Transaction, {
  TransactionCreate,
  TransactionDetail,
} from './pages/transaction';

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path='/' element={<Landing />} />

        <Route path='/auth/signin' element={<Signin />} />
        <Route path='/auth/signup' element={<Signup />} />
        <Route path='/auth/signout' element={<Signout />} />
        <Route path='/auth/dropuser' element={<Delete />} />

        <Route path='/profile' element={<Profile />} />

        <Route
          path='/'
          element={
            <ProtectedRoute>
              <SharedLayout />
            </ProtectedRoute>
          }
        >
          <Route path='/home' element={<Home />} />

          <Route path='/account' element={<Account />} />
          <Route path='/account/create' element={<AccountCreate />} />
          <Route
            path='/account/detail/:accountId'
            element={<AccountDetail />}
          />
          <Route
            path='/account/history/:accountId'
            element={<AccountHistory />}
          />

          <Route path='/income' element={<Income />} />
          <Route path='/cashflow' element={<Cashflow />} />
          <Route path='/payment/success' element={<Success />} />
          <Route path='/payment/failure' element={<Failure />} />

          <Route path='/transaction' element={<Transaction />} />
          <Route path='/transaction/create' element={<TransactionCreate />} />
          <Route
            path='/transaction/:transactionId'
            element={<TransactionDetail />}
          />
        </Route>

        <Route path='*' element={<Error />} />
      </Routes>
      <ToastContainer position='top-left' autoClose={1000} />
      <Footer />
    </Router>
  );
}

export default App;
