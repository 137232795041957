import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signupUser } from '../../features/user/userSlice';
import { useDispatch } from 'react-redux';
import GoogleButton from 'react-google-button';
import { Button } from '@mui/material';
const { FullButton } = require('../../components');

const Signup = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const onSubmit = async (event) => {
    event.preventDefault();
    dispatch(signupUser({ email, password })).then((res) => {
      if (res.meta.requestStatus === 'fulfilled') {
        setTimeout(() => navigate('/', { replace: true }), 3000);
      }
    });
  };

  return (
    <div className='container d-grid gap-2'>
      <h3>Sign Up</h3>
      <div className='form-group'>
        <label>Email Address</label>
        <input
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          className='form-control'
        />
      </div>
      <div className='form-group'>
        <label>Password</label>
        <input
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          type='password'
          className='form-control'
        />
      </div>
      <FullButton text='Sign Up' color='primary' onClick={onSubmit} />
      <a href='/api/auth/google'>
        <GoogleButton className='w-100' type='light' />
      </a>
    </div>
  );
};

export default Signup;
