import React, { useEffect } from 'react';
import Entry from './Entry';
import { addEntry } from '../features/transaction/transactionSlice';
import { useDispatch, useSelector } from 'react-redux';
import {
  editTransaction,
  toggleClosing,
  resetTransaction,
} from '../features/transaction/transactionSlice';
import { Switch, Tooltip } from '@mui/material';
import Equation from './Equation';

const Transaction = () => {
  const dispatch = useDispatch();
  const { accounts } = useSelector((store) => store.account);
  const { transaction, isNew } = useSelector((store) => store.transaction);

  useEffect(() => {
    return () => {
      dispatch(resetTransaction());
    };
  }, []);

  const handleAddEntry = (e) => {
    e.preventDefault();

    const account = accounts[0];
    let entry = {
      amount: 0,
      type: 'debit',
      accountId: account.id,
      accountType: account.type,
    };

    // only try to balance the first entry, subsequent addition is confusing
    if (transaction.entries.length == 1) {
      const lastEntry = transaction.entries[transaction.entries.length - 1];
      entry.amount = lastEntry.amount;
      entry.type = lastEntry.type === 'debit' ? 'credit' : 'debit';
    }
    dispatch(addEntry({ entry }));
  };

  const handleInputChange = (e) => {
    var { name, value } = e.target;
    dispatch(editTransaction({ name, value }));
  };

  const transactionDetail = (
    <div className='row'>
      <div className='col-sm-9'>
        <b>Title</b>
        <input
          className='form-control'
          name='memo'
          value={transaction.memo}
          onChange={handleInputChange}
        />
      </div>
      <div className='col-sm-3'>
        <b>Date</b>
        <input
          className='form-control'
          type='date'
          name='dt'
          value={transaction.dt}
          onChange={handleInputChange}
        ></input>
      </div>
    </div>
  );

  const header = (
    <div className='row'>
      <div className='col-sm-8'>
        <b>Account</b>
      </div>
      <div className='col-sm-2'>
        <b>Amount (cent)</b>
      </div>
    </div>
  );

  const entryGroup = transaction.entries.map((entry, index) => (
    <Entry key={index} index={index} entry={entry} />
  ));

  return (
    <>
      {transactionDetail}
      {header}
      {entryGroup}
      <div className='row'>
        <div className='col-sm-10'></div>
        <div className='col-sm-2'>
          <button
            onClick={handleAddEntry}
            className='btn btn-outline-secondary w-100'
          >
            + Entry
          </button>
        </div>
      </div>
      <p>
        <Tooltip title='Reset temporary/trial balance to zero at the end of accounting cycle. Only available when creating new transaction.'>
          <b>Closing Entries</b>
        </Tooltip>
        <Switch
          disabled={!isNew}
          checked={transaction.is_closing}
          onChange={() => dispatch(toggleClosing({ accounts }))}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </p>
      <Equation />
    </>
  );
};

export default Transaction;
