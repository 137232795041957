const usd = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export const format = (balance) => {
  balance /= 100;
  if (balance < 0) {
    // If the balance is negative, enclose it in brackets
    return `(${usd.format(Math.abs(balance))})`;
  } else {
    // If the balance is non-negative, format it with the currency symbol
    return usd.format(balance);
  }
};

export const USER_LOAD = 'USER_LOAD';
export const USER_SIGNIN = 'USER_SIGNIN';
