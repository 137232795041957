import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const Failure = () => {
  const navigate = useNavigate();

  useEffect(() => {
    setTimeout(() => {
      navigate('/profile');
    }, 3000);
  }, []);

  return (
    <div className='container'>
      <h1>Payment Failed</h1>
      <p>Please try again.</p>
    </div>
  );
};

export default Failure;
