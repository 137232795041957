import React, { useEffect } from 'react';
import { format } from '../../utils';
import { useSelector, useDispatch } from 'react-redux';
import { getIncome } from '../../features/income/incomeSlice';
import { toggleShowAll } from '../../features/filter/filterSlice';
import { DateFilter, IncomeSection } from '../../components';
import { Switch } from '@mui/material';

const Income = () => {
  const dispatch = useDispatch();
  const { statement } = useSelector((store) => store.income);
  const { showAll } = useSelector((store) => store.filter);
  const { start_dt, end_dt } = useSelector((store) => store.filter);

  useEffect(() => {
    dispatch(getIncome());
  }, [start_dt, end_dt]);

  return (
    <div className='container d-grid gap-2'>
      <h3>Income Statement</h3>
      <DateFilter />

      <IncomeSection
        accounts={statement.revenue_groups}
        title='Revenue'
        total={statement.revenue.balance}
      />
      <IncomeSection
        accounts={statement.op_expense_groups}
        title='Operating Expense'
        total={statement.op_expense.balance}
      />
      <IncomeSection
        accounts={statement.gnl_groups}
        title='Other Revenue/Expense'
        total={statement.gnl.balance}
      />
      <IncomeSection
        accounts={statement.tax_expense_groups}
        title='Tax Expense'
        total={statement.tax_expense.balance}
      />
      <table className='table'>
        <thead>
          <tr>
            <th width='40%'>Net Income</th>
            <th width='40%'>{format(statement.net_income)}</th>
            <th></th>
          </tr>
        </thead>
      </table>

      <p>
        Show All Accounts
        <Switch
          checked={showAll}
          onChange={() => dispatch(toggleShowAll())}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </p>
    </div>
  );
};

export default Income;
