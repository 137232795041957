import React, { useEffect } from 'react';
import { format } from '../../utils';
import { useSelector, useDispatch } from 'react-redux';
import { getCashflow } from '../../features/cashflow/cashflowSlice';
import { toggleShowAll } from '../../features/filter/filterSlice';
import { DateFilter, IncomeSection } from '../../components';
import { Switch } from '@mui/material';

const Cashflow = () => {
  const dispatch = useDispatch();
  const { statement } = useSelector((store) => store.cashflow);
  const { showAll } = useSelector((store) => store.filter);
  const { start_dt, end_dt } = useSelector((store) => store.filter);

  useEffect(() => {
    dispatch(getCashflow());
  }, [start_dt, end_dt]);

  return (
    <div className='container d-grid gap-2'>
      <h3>Cashflow Statement</h3>
      <DateFilter />

      <table className='table'>
        <thead>
          <tr>
            <th width='40%'>Net Income</th>
            <th width='40%'>{format(statement.net_income)}</th>
            <th></th>
          </tr>
        </thead>
      </table>

      <IncomeSection
        accounts={statement.operating_group}
        title='Cash from Operations'
        total={statement.operating.balance}
      />

      <IncomeSection
        accounts={statement.investing_group}
        title='Cash from Investing'
        total={statement.investing.balance}
      />

      <IncomeSection
        accounts={statement.financing_group}
        title='Cash from Financing'
        total={statement.financing.balance}
      />

      <table className='table'>
        <thead>
          <tr>
            <th width='40%'>Net Cashflow</th>
            <th width='40%'>{format(statement.net_cashflow)}</th>
            <th></th>
          </tr>
        </thead>
      </table>

      <p>
        Show All Accounts
        <Switch
          checked={showAll}
          onChange={() => dispatch(toggleShowAll())}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </p>
    </div>
  );
};

export default Cashflow;
