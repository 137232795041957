import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getCurrentUser } from '../features/user/userSlice';
import { toast } from 'react-toastify';

const ProtectedRoute = ({ children }) => {
  const dispatch = useDispatch();
  const { access, beta } = useSelector((store) => store.user);

  useEffect(() => {
    dispatch(getCurrentUser());
  }, []);

  const { user } = useSelector((store) => store.user);
  if (!user) {
    return <Navigate to='/' />;
  }

  if (!access && !beta) {
    toast('Your free trial / subscription has expired. Please renew.');
    return <Navigate to='/profile' />;
  }

  return children;
};

export default ProtectedRoute;
