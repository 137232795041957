import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Account, FullButton } from '../../components';
import {
  resetAccount,
  createAccount,
} from '../../features/account/accountSlice';

const AccountCreate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetAccount());
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    dispatch(createAccount()).then((res) => {
      if (res.meta.requestStatus === 'fulfilled') {
        navigate('/account', { replace: true });
      }
    });
  };

  return (
    <div>
      <div className='container d-grid gap-2'>
        <Account />
        <FullButton text='Create' color='primary' onClick={onSubmit} />
        <FullButton
          text='Cancel'
          color='secondary'
          onClick={() => navigate(-1)}
        />
      </div>
    </div>
  );
};

export default AccountCreate;
