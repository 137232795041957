import axios from '../../utils/axios';
import { resetAccountState } from '../account/accountSlice';
import { resetCashflowState } from '../cashflow/cashflowSlice';
import { resetChartState } from '../chart/chartSlice';
import { resetIncomeState } from '../income/incomeSlice';
import {
  resetTransaction,
  resetTransactions,
} from '../transaction/transactionSlice';
import { resetUserState } from './userSlice';

export const getCurrentUserThunk = async (user, thunkApi) => {
  try {
    const res = await axios.get('/auth/currentUser', user);
    return res.data;
  } catch (err) {
    return thunkApi.rejectWithValue(err.response.data);
  }
};

export const signupUserThunk = async (user, thunkApi) => {
  try {
    const res = await axios.post('/auth/signup', user);
    return res.data;
  } catch (err) {
    return thunkApi.rejectWithValue(err.response.data);
  }
};

export const signinUserThunk = async (user, thunkApi) => {
  try {
    const res = await axios.post('/auth/signin', user);
    return res.data;
  } catch (err) {
    return thunkApi.rejectWithValue(err.response.data);
  }
};

const reset = (thunkApi) => {
  thunkApi.dispatch(resetAccountState());
  thunkApi.dispatch(resetChartState());
  thunkApi.dispatch(resetUserState());
  thunkApi.dispatch(resetIncomeState());
  thunkApi.dispatch(resetCashflowState());
  thunkApi.dispatch(resetTransaction());
  thunkApi.dispatch(resetTransactions());
};

export const signoutUserThunk = async (user, thunkApi) => {
  try {
    const res = await axios.post('/auth/signout', user);
    reset(thunkApi);
    return res.data;
  } catch (err) {
    return thunkApi.rejectWithValue(err.response.data);
  }
};

export const dropUserThunk = async (user, thunkApi) => {
  try {
    const res = await axios.delete('/auth/dropuser', user);
    reset(thunkApi);
    return res.data;
  } catch (err) {
    return thunkApi.rejectWithValue(err.response.data);
  }
};
