import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import {
  addToLocalStorage,
  getFromLocalStorage,
  removeFromLocalStorage,
} from '../../utils/localStorage';
import {
  signinUserThunk,
  signupUserThunk,
  signoutUserThunk,
  getCurrentUserThunk,
  dropUserThunk,
} from './userThunk';
import { displayErrors } from '../../utils/toast';

/**
 * Use arrow function which returns the state, if refresh before logout,
 * the state will contain local storage copy and user will not be deleted */
const getInitialState = () => {
  const state = {
    isLoading: false,
    isSidebarOpen: false,
    user: null,
    access: false,
    // allow access to all
    beta: false,
    subscription: 'inactive',
  };

  const user = getFromLocalStorage('user');
  if (user) {
    synUser(state, user);
  }
  return state;
};

export const getCurrentUser = createAsyncThunk(
  'user/getCurrentUser',
  getCurrentUserThunk
);

export const signinUser = createAsyncThunk('user/signinUser', signinUserThunk);

export const signupUser = createAsyncThunk('user/signupUser', signupUserThunk);

export const dropUser = createAsyncThunk('user/dropUser', dropUserThunk);

export const signoutUser = createAsyncThunk(
  'user/signoutUser',
  signoutUserThunk
);

const synUser = (state, user) => {
  state.user = user;
  const { subscribed, trial_end } = user.profile;
  const free_trial = trial_end !== null && new Date(trial_end) >= new Date();
  state.access = subscribed || free_trial;
  if (subscribed) {
    state.subscription = 'active';
  } else if (free_trial) {
    state.subscription = 'trial ends ' + trial_end;
  }
};

const userSlice = createSlice({
  name: 'user',
  initialState: getInitialState(),
  reducers: {
    toggleSidebar: (state) => {
      state.isSidebarOpen = !state.isSidebarOpen;
    },
    resetUserState: (state) => {
      removeFromLocalStorage('user');
      return getInitialState();
    },
  },
  extraReducers: {
    [getCurrentUser.pending]: (state) => {
      state.isLoading = true;
    },
    [getCurrentUser.fulfilled]: (state, { payload }) => {
      const user = payload;
      state.isLoading = false;
      synUser(state, user);
      addToLocalStorage('user', user);
    },
    [getCurrentUser.rejected]: (state, { payload }) => {
      state.isLoading = false;
      displayErrors(payload.errors);
    },
    [signupUser.pending]: (state) => {
      state.isLoading = true;
    },
    [signupUser.fulfilled]: (state, { payload: user }) => {
      state.isLoading = false;
      synUser(state, user);
      addToLocalStorage('user', user);

      const name = user.profile.email.split('@')[0];
      toast.success(`Welcome, ${name}`);
    },
    [signupUser.rejected]: (state, { payload }) => {
      state.isLoading = false;
      displayErrors(payload.errors);
    },
    [signinUser.pending]: (state) => {
      state.isLoading = true;
    },
    [signinUser.fulfilled]: (state, { payload: user }) => {
      state.isLoading = false;
      synUser(state, user);
      addToLocalStorage('user', user);

      const name = state.user.profile.email.split('@')[0];
      toast.success(`Welcome back, ${name}`);
    },
    [signinUser.rejected]: (state, { payload }) => {
      state.isLoading = false;
      displayErrors(payload.errors);
    },
    [signoutUser.pending]: (state) => {
      state.isLoading = true;
    },
    [signoutUser.fulfilled]: (state) => {
      state.user = null;
      state.isLoading = false;
      toast.success('Goodbye');
    },
    [signoutUser.rejected]: (state, { payload }) => {
      state.user = null;
      state.isLoading = false;
      toast.success('Goodbye');
    },
    [dropUser.pending]: (state) => {
      state.isLoading = true;
    },
    [dropUser.fulfilled]: (state) => {
      state.isLoading = false;
    },
    [dropUser.rejected]: (state, { payload }) => {
      state.isLoading = false;
      displayErrors(payload.errors);
    },
  },
});

export const { toggleSidebar, resetUserState } = userSlice.actions;
export default userSlice.reducer;
