import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { filterAccounts } from '../features/account/accountSlice';
import { format } from '../utils';

const IncomeSection = ({ accounts, title, total }) => {
  const { showAll } = useSelector((store) => store.filter);
  const show = filterAccounts(showAll, accounts);
  const hidden_count = accounts.length - show.length;

  return (
    <table className='table'>
      <thead>
        <tr>
          <th width='40%'>
            {title + ' '}
            {hidden_count > 0 && showAll == false
              ? `(${hidden_count} Hidden)`
              : ''}
          </th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        {show.map((group) => {
          return (
            <tr key={group.id}>
              <td width='40%'>{group.name}</td>
              <td width='40%'>{format(group.balance)}</td>
              <td>
                <Link to={`/account/history/${group.id}`}>View</Link>
              </td>
            </tr>
          );
        })}
      </tbody>
      <tfoot>
        <tr>
          <th width='40%'>Subtotal</th>
          <th width='40%'>{format(total)}</th>
          <th></th>
        </tr>
      </tfoot>
    </table>
  );
};

export default IncomeSection;
