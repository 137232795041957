export const getMonthStart = () => {
  const today = new Date();
  const date = new Date(today.getFullYear(), today.getMonth(), 1);

  return new Date(date.setHours(0, 0, 0, 0)).toISOString().split('T')[0];
};

export const getMonthEnd = () => {
  const today = new Date();
  const date = new Date(today.getFullYear(), today.getMonth() + 1, 0);

  return new Date(date.setHours(0, 0, 0, 0)).toISOString().split('T')[0];
};
