import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toggleEquation } from '../features/transaction/transactionSlice';
import { Switch, Tooltip } from '@mui/material';
import { format } from '../utils';

const transactionTotal = (entries, accountType) => {
  const isAsset = accountType === 'asset' ? 1 : -1;
  return entries
    .filter((x) => x.accountType === accountType)
    .reduce((acc, cur) => {
      const isDebit = cur.type === 'debit' ? 1 : -1;
      return acc + parseInt(cur.amount) * (isAsset * isDebit);
    }, 0);
};

const netTotal = (accounts, accountType) => {
  return accounts
    .filter((x) => x.type === accountType)
    .reduce((acc, cur) => {
      return acc + parseInt(cur.balance);
    }, 0);
};

const Equation = () => {
  const dispatch = useDispatch();
  const { transaction, show_equation, isNew } = useSelector(
    (store) => store.transaction
  );

  const { accounts } = useSelector((store) => store.account);
  const [isBalanced, setIsBalanced] = useState(true);

  const [assetBalance, setAssetBalance] = useState(0);
  const [liabilityBalance, setLiabilityBalance] = useState(0);
  const [equityBalance, setEquityBalance] = useState(0);
  const [temporaryBalance, setTemporaryBalance] = useState(0);

  const [assetChange, setAssetChange] = useState(0);
  const [liabilityChange, setLiabilityChange] = useState(0);
  const [equityChange, setEquityChange] = useState(0);
  const [temporaryChange, setTemporaryChange] = useState(0);

  useEffect(() => {
    const assetBalance = netTotal(accounts, 'asset');
    const liabilityBalance = netTotal(accounts, 'liability');
    const equityBalance = netTotal(accounts, 'equity');
    const temporaryBalance = netTotal(accounts, 'temporary');

    // if transaction is new, we project the balance after the transaction
    // if transaction is old, the ending balance should be current balance
    if (isNew) {
      setAssetBalance(assetBalance);
      setLiabilityBalance(liabilityBalance);
      setEquityBalance(equityBalance);
      setTemporaryBalance(temporaryBalance);
    } else {
      const assetChange = transactionTotal(transaction.entries, 'asset');
      const liabilityChange = transactionTotal(
        transaction.entries,
        'liability'
      );
      const equityChange = transactionTotal(transaction.entries, 'equity');
      const temporaryChange = transactionTotal(
        transaction.entries,
        'temporary'
      );

      setAssetBalance(assetBalance - assetChange);
      setLiabilityBalance(liabilityBalance - liabilityChange);
      setEquityBalance(equityBalance - equityChange);
      setTemporaryBalance(temporaryBalance - temporaryChange);
    }
  }, []);
  useEffect(() => {
    const assetChange = transactionTotal(transaction.entries, 'asset');
    const liabilityChange = transactionTotal(transaction.entries, 'liability');
    const equityChange = transactionTotal(transaction.entries, 'equity');
    const temporaryChange = transactionTotal(transaction.entries, 'temporary');

    const isBalanced =
      assetChange === liabilityChange + equityChange + temporaryChange;
    setIsBalanced(isBalanced);

    setAssetChange(assetChange);
    setLiabilityChange(liabilityChange);
    setEquityChange(equityChange);
    setTemporaryChange(temporaryChange);
  }, [transaction]);

  const table = (
    <>
      <div className='row'>
        <div className='col-sm-1'></div>
        <div className='col-sm-2'>
          <b>Asset</b>
        </div>
        <div className='col-sm-1'>=</div>
        <div className='col-sm-2'>
          <b>Liability</b>
        </div>
        <div className='col-sm-1'>+</div>
        <div className='col-sm-2'>
          <b>Equity</b>
        </div>
        <div className='col-sm-1'>+</div>
        <div className='col-sm-2'>
          <b>Temporary</b>
        </div>
      </div>

      <div className='row'>
        <div className='col-sm-1'>
          <b>Before</b>
        </div>
        <div className='col-sm-2'>{format(assetBalance)}</div>
        <div className='col-sm-1'>=</div>
        <div className='col-sm-2'>{format(liabilityBalance)}</div>
        <div className='col-sm-1'>+</div>
        <div className='col-sm-2'>{format(equityBalance)}</div>
        <div className='col-sm-1'>+</div>
        <div className='col-sm-2'>{format(temporaryBalance)}</div>
      </div>

      <div className='row'>
        <div className='col-sm-1'>
          <b>Change</b>
        </div>
        <div className='col-sm-2'>{format(assetChange)}</div>
        <div className='col-sm-1'>=</div>
        <div className='col-sm-2'>{format(liabilityChange)}</div>
        <div className='col-sm-1'>+</div>
        <div className='col-sm-2'>{format(equityChange)}</div>
        <div className='col-sm-1'>+</div>
        <div className='col-sm-2'>{format(temporaryChange)}</div>
      </div>

      <div className='row'>
        <div className='col-sm-1'>
          <b>After</b>
        </div>
        <div className='col-sm-2'>{format(assetBalance + assetChange)}</div>
        <div className='col-sm-1'>=</div>
        <div className='col-sm-2'>
          {format(liabilityBalance + liabilityChange)}
        </div>
        <div className='col-sm-1'>+</div>
        <div className='col-sm-2'>{format(equityBalance + equityChange)}</div>
        <div className='col-sm-1'>+</div>
        <div className='col-sm-2'>
          {format(temporaryBalance + temporaryChange)}
        </div>
      </div>

      <div className='col-sm-3'>
        {isBalanced ? (
          <span style={{ color: 'green' }}>
            <b>Accounts are balanced!</b>
          </span>
        ) : (
          <span style={{ color: 'red' }}>
            <b>Accounts are not balanced!</b>
          </span>
        )}
      </div>
    </>
  );
  return (
    <>
      <p>
        <Tooltip title='The accounting equation must always balance before and after the transaction.'>
          <b>Check Balance</b>
        </Tooltip>
        <Switch
          checked={show_equation}
          onChange={() => dispatch(toggleEquation())}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </p>
      {show_equation && table}
    </>
  );
};

export default Equation;
