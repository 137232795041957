import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getIncomeThunk } from './incomeThunk';
import { displayErrors } from '../../utils/toast';

const initialState = {
  isLoading: false,
  statement: {
    revenue: {
      debit: 0,
      credit: 0,
      balance: 0,
    },
    op_expense: {
      debit: 0,
      credit: 0,
      balance: 0,
    },
    tax_expense: {
      debit: 0,
      credit: 0,
      balance: 0,
    },
    gnl: {
      debit: 0,
      credit: 0,
      balance: 0,
    },
    revenue_groups: [],
    op_expense_groups: [],
    tax_expense_groups: [],
    gnl_groups: [],
    net_income: 0,
  },
};

export const getIncome = createAsyncThunk('statement/income', getIncomeThunk);

const incomeSlice = createSlice({
  name: 'income',
  initialState,
  reducers: {
    resetIncomeState: (state) => {
      // called when user signs out
      return initialState;
    },
  },
  extraReducers: {
    [getIncome.pending]: (state) => {
      state.isLoading = true;
    },
    [getIncome.fulfilled]: (state, { payload }) => {
      state.isLoading = false;

      state.statement = payload;
    },
    [getIncome.rejected]: (state, { payload }) => {
      state.isLoading = false;
      displayErrors(payload.errors);
    },
  },
});

export const { resetIncomeState } = incomeSlice.actions;
export default incomeSlice.reducer;
