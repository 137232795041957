import axios from '../../utils/axios';

export const getPaymentCheckoutThunk = async (_, thunkApi) => {
  try {
    const { plan } = thunkApi.getState().payment;
    const res = await axios.post(`/payment/checkout`, { plan });
    return res.data;
  } catch (err) {
    return thunkApi.rejectWithValue(err.response.data);
  }
};

export const getPaymentPortalThunk = async (_, thunkApi) => {
  try {
    const res = await axios.post(`/payment/portal`);
    return res.data;
  } catch (err) {
    return thunkApi.rejectWithValue(err.response.data);
  }
};
