import {
  setIsNew,
  createTransaction,
  resetTransaction,
} from '../../features/transaction/transactionSlice';
import { useNavigate } from 'react-router-dom';
import { Transaction, FullButton } from '../../components';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';

const TransactionCreate = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setIsNew({ isNew: true }));
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(createTransaction()).then((res) => {
      if (res.meta.requestStatus === 'fulfilled') {
        dispatch(resetTransaction());
        setTimeout(() => navigate(-1), 1000);
      }
    });
  };

  return (
    <div>
      <div className='container d-grid gap-2'>
        <h4>New Transaction</h4>
        <Transaction />
        <FullButton text='Create' color='primary' onClick={handleSubmit} />
        <FullButton
          text='Cancel'
          color='secondary'
          onClick={() => {
            dispatch(resetTransaction());
            navigate(-1);
          }}
        />
      </div>
    </div>
  );
};

export default TransactionCreate;
