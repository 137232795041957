import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { format } from '../../utils';
import { useSelector, useDispatch } from 'react-redux';
import {
  filterAccounts,
  getAccounts,
} from '../../features/account/accountSlice';
import { toggleShowAll } from '../../features/filter/filterSlice';
import { setPage } from '../../features/transaction/transactionSlice';
import { Switch } from '@mui/material';
import { FullButton } from '../../components';

// Not allowed to fetch data in component in server-side render
const AccountIndex = () => {
  const dispatch = useDispatch();
  const { accounts } = useSelector((store) => store.account);
  const { showAll } = useSelector((store) => store.filter);
  const { transaction } = useSelector((store) => store.transaction);

  useEffect(() => {
    dispatch(getAccounts());
  }, [transaction]);

  const getSection = (type) => {
    const subset = accounts.filter((account) => account.type == type);
    const subtotal = subset.reduce((acc, account) => {
      return acc + account.balance;
    }, 0);

    const show = filterAccounts(showAll, subset);
    const hiddenCount = subset.length - show.length;
    const body = show.map((account) => {
      return (
        <tr key={account.id}>
          <td width='40%'>{account.name}</td>
          <td width='40%'>
            {account.hidden ? '$********.**' : format(account.balance)}
          </td>
          <td>
            <Link
              to={`/account/history/${account.id}`}
              onClick={() => dispatch(setPage({ page: 1 }))}
            >
              View
            </Link>
          </td>
        </tr>
      );
    });
    return (
      <table className='table'>
        <thead>
          <tr>
            <th width='40%'>
              {type.toUpperCase()}{' '}
              {hiddenCount > 0 && showAll == false
                ? `(${hiddenCount} Hidden)`
                : ''}
            </th>
            <th width='40%'>Balance</th>
          </tr>
        </thead>
        <tbody>
          {body}
          <tr>
            <td width='40%'>
              <b>Subtotal</b>
            </td>
            <td>{format(subtotal)}</td>
          </tr>
        </tbody>
      </table>
    );
  };

  const asset = getSection('asset');
  const liability = getSection('liability');
  const equity = getSection('equity');
  const temporary = getSection('temporary');
  return (
    <div className='container d-grid gap-2'>
      <h3>Balance Sheet</h3>
      {asset}
      {liability}
      {equity}
      {temporary}
      <Link to='/account/create'>
        <FullButton text='New Account' color='primary' />
      </Link>
      <p>
        Show All Accounts
        <Switch
          checked={showAll}
          onChange={() => dispatch(toggleShowAll())}
          inputProps={{ 'aria-label': 'controlled' }}
        />
      </p>
    </div>
  );
};

AccountIndex.getInitialProps = async (context, axios, currentUser) => {
  const { data } = await axios.get('/api/balance/current');
  return { accounts: data.accounts, currentUser };
};

export default AccountIndex;

import AccountCreate from './Create';
import AccountDetail from './Detail';
import AccountHistory from './History';
export { AccountCreate, AccountDetail, AccountHistory };
