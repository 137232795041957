import React, { useEffect } from 'react';

import { HiChevronDoubleLeft, HiChevronDoubleRight } from 'react-icons/hi';
import Wrapper from '../assets/wrappers/PageButtonContainer';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

import { useDispatch } from 'react-redux';

const PageButtonContianer = ({
  totalPages = 10,
  page = 1,
  setPage,
  limit = 15,
  setLimit, // do not use store default, use container defined setLimitAndFetchPage
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      dispatch(setPage({ page: 1 }));
    };
  }, []);

  const range = (start, end) =>
    [...Array(end - start + 1)].map((_, i) => start + i);

  const shift = Math.min(totalPages - 10, Math.max(0, Math.ceil(page - 5.5)));
  const pages = range(Math.max(1, 1 + shift), Math.min(totalPages, 10 + shift));

  const nextPage = (e) => {
    e.preventDefault();
    dispatch(setPage({ page: Math.min(page + 1, totalPages) }));
  };

  const prevPage = (e) => {
    e.preventDefault();
    dispatch(setPage({ page: Math.max(page - 1, 1) }));
  };

  const sizes = [15, 25, 50, 100];

  return (
    <Wrapper>
      <button type='button' className='prev-btn' onClick={prevPage}>
        <HiChevronDoubleLeft />
        prev
      </button>
      <div className='btn-container'>
        {pages.map((pageNumber) => (
          <button
            type='button'
            key={pageNumber}
            className={pageNumber === page ? 'pageBtn active' : 'pageBtn'}
            onClick={() => dispatch(setPage({ page: pageNumber }))}
          >
            {pageNumber}
          </button>
        ))}
      </div>
      <button type='button' className='next-btn' onClick={nextPage}>
        next
        <HiChevronDoubleRight />
      </button>

      <label>Show</label>
      <FormControl sx={{ m: 1 }} variant='standard'>
        <Select
          labelId='demo-customized-select-label'
          id='demo-customized-select'
          value={limit}
          onChange={(e) => setLimit(e.target.value)}
        >
          {sizes.map((size) => (
            <MenuItem value={size} key={size}>
              {size}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <label>Per Page</label>
    </Wrapper>
  );
};

export default PageButtonContianer;
