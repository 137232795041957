import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Account, FullButton } from '../../components';
import {
  updateAccount,
  deleteAccount,
  resetAccount,
} from '../../features/account/accountSlice';

const AccountDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onSubmit = async (e) => {
    e.preventDefault();
    dispatch(updateAccount()).then((res) => {
      if (res.meta.requestStatus === 'fulfilled') {
        navigate(-1);
      }
    });
  };

  const handleDelete = async (e) => {
    e.preventDefault();
    dispatch(deleteAccount()).then((res) => {
      if (res.meta.requestStatus === 'fulfilled') {
        dispatch(resetAccount());
        setTimeout(() => navigate(-2), 1000);
      }
    });
  };

  return (
    <div className='container d-grid gap-2'>
      <Account />
      <FullButton text='Update' color='primary' onClick={onSubmit} />
      <FullButton
        text='Cancel'
        color='secondary'
        onClick={() => navigate(-1)}
      />
      <FullButton text='Delete' color='error' onClick={handleDelete} />
    </div>
  );
};

export default AccountDetail;
