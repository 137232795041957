import React, { useEffect } from 'react';
import { PageButtonContianer, Transactions } from '../../components';
import { useSelector, useDispatch } from 'react-redux';
import {
  getTransactions,
  resetTransaction,
  setLimit,
} from '../../features/transaction/transactionSlice';
import { setPage } from '../../features/transaction/transactionSlice';
import { Link } from 'react-router-dom';
import { DateFilter, FullButton } from '../../components';

const TransactionIndex = () => {
  const dispatch = useDispatch();
  const { transactions } = useSelector((store) => store.transaction);
  const { start_dt, end_dt } = useSelector((store) => store.filter);

  useEffect(() => {
    dispatch(setPage({ page: 1 }));
  }, []);

  useEffect(() => {
    dispatch(getTransactions());
  }, [start_dt, end_dt, transactions.page]);

  const setLimitAndFetchPage = (limit) => {
    dispatch(setLimit({ limit }));
    dispatch(setPage({ page: 1 }));
    dispatch(getTransactions());
  };

  return (
    <div className='container d-grid gap-2'>
      <h3>General Ledger</h3>
      <DateFilter />
      <Transactions />
      <PageButtonContianer
        totalPages={transactions.totalPages}
        page={transactions.page}
        setPage={setPage}
        limit={transactions.limit}
        setLimit={setLimitAndFetchPage}
      />

      <Link
        to='/transaction/create'
        onClick={() => dispatch(resetTransaction())}
      >
        <FullButton text='New Transaction' color='primary' />
      </Link>
    </div>
  );
};

export default TransactionIndex;

import TransactionCreate from './Create';
import TransactionDetail from './Detail';

export { TransactionCreate, TransactionDetail };
