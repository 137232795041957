import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ToggleButtonGroup, ToggleButton, capitalize } from '@mui/material';
import {
  deleteEntry,
  updateEntry,
} from '../features/transaction/transactionSlice';

const Entry = ({ index, entry }) => {
  const dispatch = useDispatch();
  var { accounts } = useSelector((store) => store.account);
  const getAccount = (id) => accounts.filter((acc) => acc.id === id)[0];
  const [inputValue, setInputValue] = useState(entry.amount);

  useEffect(() => {
    setInputValue(entry.amount);
  }, [entry.amount]);

  const handleUpdateEntry = (e) => {
    var { name, value } = e.target;
    if (name === 'accountId') {
      const selectedAccount = getAccount(value);
      dispatch(
        updateEntry({ index, name: 'accountType', value: selectedAccount.type })
      );
    } else if (name === 'amount') {
      value = value.replace(/[^\d]/g, '');
      if (value == '') value = 0;
      setInputValue(value);
      value = parseInt(value);
    }
    dispatch(updateEntry({ index, name, value }));
  };

  const updateDebitCredit = (e) => {
    var { value } = e.target;
    dispatch(updateEntry({ index, name: 'type', value }));
  };

  return (
    <div className='row'>
      <div className='col-sm-6'>
        <select
          className='form-control'
          name='accountId'
          value={entry.accountId}
          onChange={handleUpdateEntry}
        >
          {accounts
            .filter((x) => !x.hidden)
            .map((account) => (
              <option value={account.id} key={account.id}>
                {account.name + ' (' + capitalize(account.type) + ')'}
              </option>
            ))}
        </select>
      </div>

      <div className='col-sm-2'>
        <ToggleButtonGroup
          color='primary'
          size='small'
          name='type'
          value={entry.type}
          exclusive
          onChange={updateDebitCredit}
          aria-label='Platform'
        >
          <ToggleButton value='debit'>Debit</ToggleButton>
          <ToggleButton value='credit'>Credit</ToggleButton>
        </ToggleButtonGroup>
      </div>

      <div className='col-sm-2'>
        <input
          className='form-control'
          name='amount'
          value={inputValue}
          onChange={handleUpdateEntry}
        />
      </div>

      <div className='col-sm-2'>
        <button
          onClick={() => dispatch(deleteEntry({ index }))}
          className='btn btn-outline-secondary w-100'
        >
          - Entry
        </button>
      </div>
    </div>
  );
};

export default Entry;
