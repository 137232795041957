import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getCashflowThunk } from './cashflowThunk';
import { displayErrors } from '../../utils/toast';

const initialState = {
  isLoading: false,
  statement: {
    net_income: 0,
    operating_group: [],
    investing_group: [],
    financing_group: [],
    operating: { balance: 0 },
    investing: { balance: 0 },
    financing: { balance: 0 },
    net_cashflow: 0,
  },
};

export const getCashflow = createAsyncThunk(
  'statement/cashflow',
  getCashflowThunk
);

const cashflowSlice = createSlice({
  name: 'cashflow',
  initialState,
  reducers: {
    resetCashflowState: (state) => {
      // called when user signs out
      return initialState;
    },
  },
  extraReducers: {
    [getCashflow.pending]: (state) => {
      state.isLoading = true;
    },
    [getCashflow.fulfilled]: (state, { payload }) => {
      state.isLoading = false;
      state.statement = payload;
    },
    [getCashflow.rejected]: (state, { payload }) => {
      state.isLoading = false;
      displayErrors(payload.errors);
    },
  },
});

export const { resetCashflowState } = cashflowSlice.actions;
export default cashflowSlice.reducer;
